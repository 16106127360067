import React, { Fragment } from "react"
import Layout from "../../components/layout"
import {
    StyledSection,
    StyledCardGridWrapper,
    StyledGrid,
    StyledHeadline,
} from "../../styles/global"
import Card from "../../components/card"
import { ILocation } from "../../interface/location"
import styled from "styled-components"

const StyledArticleEntryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const StyledCardWrapper = styled.div`
 p {
    font-size: 18px;
}
`

const TipOgRadindex: React.FC<{ location: ILocation }> = ({
    location,
}: any) => {
    const ingress = "Her finner du alt om skadevern tips og råd"
    const pagename = "Tips og råd"
    const contentIngress = { ingress, pagename }
    return (
        <Fragment>
            <Layout location={location} hero={true} ingress={contentIngress}>
                <StyledSection>
                    <StyledCardGridWrapper>                        
                        <StyledArticleEntryWrapper>                            
                            <StyledGrid>
                                <StyledCardWrapper>
                                    <Card
                                        textOnly={true}
                                        text="Sjekkliste for trygg bruk av lys i julefeiringen"
                                        path={`${location.pathname}/sjekkliste` }
                                    />
                                </StyledCardWrapper>
                                
                                <StyledCardWrapper>
                                    <Card
                                        textOnly={true}
                                        text="Brannspredning via kjøkkenventilator "
                                        path={`${location.pathname}/tips1` }
                                    />    
                                </StyledCardWrapper>                                                                                            
                            </StyledGrid>
                        </StyledArticleEntryWrapper>
                    </StyledCardGridWrapper>
                </StyledSection>
            </Layout>
        </Fragment>
    )
}

export default TipOgRadindex